import React from "react";

function Terms() {
  return (
    <div className="container">
      <div className="row mt-2">
        <div className="poppins-black mt-2">Terms of Use</div>
        <p className="poppins-regular">
          As the original purchaser of content sold on proleverage, you are
          entitled to access and use the content which is identified in the
          course and which is on the proleverage website, at
          <span className="poppins-black">proleverage.io ("Website").</span> In
          order to access and use this content, you must register with
          proleverage and create a password to use in accessing the content on
          our android Appliction which is exclusive on our website itself.
        </p>
        <p className="poppins-regular">
          Your password is unique and exclusive to you, and you may not transfer
          your password to any other person or allow any other person to use
          your password to access or use content on the Website. You agree to
          notify proleverage immediately if any other person makes unauthorized
          use of your password. proleverage reserves the right to suspend or
          revoke your password in the event of any misuse of your password or
          any use of your password in violation of these Terms and Conditions.
          In the event of any such suspension or revocation, you will not be
          entitled to any refund or payment.
        </p>
        <p className="poppins-regular">
          These Terms of Use govern your access to and use of the Website and
          the content on the Website. By accessing and using the Website, you
          agree to these Terms of Use. If you do not agree to any of these Terms
          of Use, you may not access or use the site. proleverage reserves the
          right to modify these Terms of Use at any time and in its sole
          discretion. Your use of the site following any modification will
          constitute your assent to and acceptance of the modifications.
        </p>
        <p className="poppins-regular">
          Upon registration, proleverage grants you a non-exclusive,
          non-transferable, non-assignable, personal license to access and use
          the proleverage content identified in the content you purchased via an
          online/offline reader.
        </p>
        <p className="poppins-regular">
          proleverage will not be liable for any delay or interruption in your
          access to the site or any content located on the site, or for any
          transmission errors, equipment or software incompatibilities, force
          majeure or other failure of performance. proleverage will use
          reasonable efforts to correct any failure of performance, but
          proleverage will not be required to make any changes to any equipment
          or software used by proleverage or its contractors or agents to ensure
          compatibility with any equipment or software used by you. You may not
          use the site or the content on the site for any commercial purpose,
          including but not limited to the use of any of the content to market
          or sell goods or services to any person. You agree not to launch any
          automated system, including without limitation, "robots," "spiders,"
          or "offline readers," to access the site.
        </p>
        <p className="poppins-regular">
          Terms of Use As the original purchaser of content sold on proleverage,
          you are entitled to access and use the content which is identified in
          the course and which is on the proleverage website, at proleverage.io
          ("Website"). In order to access and use this content, you must
          register with proleverage and create a password to use in accessing
          the content on our android Appliction which is exclusive on our
          website itself. Your password is unique and exclusive to you, and you
          may not transfer your password to any other person or allow any other
          person to use your password to access or use content on the Website.
          You agree to notify proleverage immediately if any other person makes
          unauthorized use of your password. proleverage reserves the right to
          suspend or revoke your password in the event of any misuse of your
          password or any use of your password in violation of these Terms and
          Conditions. In the event of any such suspension or revocation, you
          will not be entitled to any refund or payment. These Terms of Use
          govern your access to and use of the Website and the content on the
          Website. By accessing and using the Website, you agree to these Terms
          of Use. If you do not agree to any of these Terms of Use, you may not
          access or use the site. proleverage reserves the right to modify these
          Terms of Use at any time and in its sole discretion. Your use of the
          site following any modification will constitute your assent to and
          acceptance of the modifications. Upon registration, proleverage grants
          you a non-exclusive, non-transferable, non-assignable, personal
          license to access and use the proleverage content identified in the
          content you purchased via an online/offline reader. proleverage will
          not be liable for any delay or interruption in your access to the site
          or any content located on the site, or for any transmission errors,
          equipment or software incompatibilities, force majeure or other
          failure of performance. proleverage will use reasonable efforts to
          correct any failure of performance, but proleverage will not be
          required to make any changes to any equipment or software used by
          proleverage or its contractors or agents to ensure compatibility with
          any equipment or software used by you. You may not use the site or the
          content on the site for any commercial purpose, including but not
          limited to the use of any of the content to market or sell goods or
          services to any person. You agree not to launch any automated system,
          including without limitation, "robots," "spiders," or "offline
          readers," to access the site. proleverage reserves the right to
          change, suspend access to, or remove any or all of the content on the
          Website at any time, for any reason, in its sole discretion.
          proleverage also reserves the right to discontinue the Website at any
          time, either temporarily or permanently. In the event of the removal
          of any content from the Website or the termination of the Website, you
          will not be entitled to any refund or payment.
        </p>
        <p className="poppins-regular">
          YOU AGREE THAT YOUR USE OF THE SITE SHALL BE AT YOUR SOLE RISK, AND
          proleverage WILL NOT BE HELD LIABLE IN ANY WAY FOR YOUR USE OF THE
          SITE OR FOR ANY INFORMATION CONTAINED ON THE SITE. ALL CONTENT
          CONTAINED IN OR REFERRED TO ON THE SITE IS PROVIDED "AS IS," WITHOUT
          ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED. TO THE FULLEST
          EXTENT PERMITTED BY LAW, proleverage DISCLAIMS ALL WARRANTIES, EXPRESS
          OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. proleverage
          MAKES NO WARRANTIES THAT THE SITE WILL BE ERROR-FREE, OR THAT ANY
          ERRORS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER FROM WHICH
          THE SITE IS OPERATED WILL BE FREE OF VIRUSES OR OTHER POTENTIALLY
          HARMFUL CODES. UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, SHALL
          proleverage BE HELD LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          SPECIAL OR CONSEQUENTIAL DAMAGES AND EXPENSES OF ANY KIND (INCLUDING,
          WITHOUT LIMITATION, PERSONAL INJURY OR PROPERTY DAMAGE, LOST PROFITS,
          AND DAMAGES ARISING FROM COMPUTER VIRUSES, BUSINESS INTERRUPTION, LOST
          DATA, UNAUTHORIZED ACCESS TO OR USE OF SITE SERVERS OR ANY PERSONAL
          INFORMATION STORED THEREIN, OR ANY INTERRUPTION OR CESSATION OF
          OPERATION OF THE SITE) ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
          USE OF THE SITE OR ANY INFORMATION CONTAINED ON THE SITE, WHETHER SUCH
          DAMAGES ARE BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE,
          EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p className="poppins-regular">
          You agree to indemnify, hold harmless and defend proleverage from and
          against any and all claims, damages, losses, liabilities, judgments,
          awards, settlements, costs and expenses (including attorney's fees and
          court costs) arising out of or resulting from your use of this Website
          or the violation by you of any of these Terms of Use.
        </p>
        <p className="poppins-regular">
          YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THIS
          SITE OR YOUR USE OF THIS SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER
          THE CAUSE OF ACTION ACCRUES, AND WILL THEREAFTER BE PERMANENTLY
          BARRED.
        </p>
        <p className="poppins-regular">
          These Terms of Use constitute the entire agreement between you and
          proleverage concerning your use of the Website and the contents of the
          Website. If any provision is deemed invalid by a court of competent
          jurisdiction, the remaining provisions shall remain in full force and
          effect. No waiver of any the Terms of Use shall be deemed a further or
          continuing waiver of such term or condition or any other term or
          condition, and any failure by proleverage to assert any right or
          provision under these Terms of Use shall not constitute a waiver of
          such right or provision.
        </p>
      </div>
    </div>
  );
}
export default Terms;
